
$color_red: #EC0016;
$color_inputFiledBG: rgba( #282D37, 0.05 );

$color_footer: #fb954b;

$color_cta: #1B0E7B;

$mobile_nav_bg: $color_footer;

$color_title: #000;
$color_text: #000;

$color_bg_lightGrey: #f2f2f6;