
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";


.Footer 
{
	height: 70px;
	background: $color-footer;
	color: #fff;

	// override global class .module
	.module {
		height: 100%;
		justify-content: center;
		align-items: center;
		margin-top: 56px;
	}

	UL
	{
		&.horizontal-list 
		{
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-direction: row;

			LI 
			{
				@include default_p();
				padding: 5px 16px;
			}
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		height: pcD(70);

		.module {
			margin-top: pcD(56);
		}

		UL
		{
			&.horizontal-list 
			{
				LI 
				{
					// @include default_p();	... Ursprung von font-size und line-height
					font-size: pcD(16);
					line-height: pcD(24);
					padding: pcD(5) pcD(16);
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		font-size: pcD(20);
									line-height: pcD(28);
		
		// height: pcD(70);

		// .module {
		// 	margin-top: pcD(56);
		// }

		UL
		{
			&.horizontal-list 
			{
				LI 
				{
					// @include default_p();	... Ursprung von font-size und line-height
					font-size: pcD(20);			// .... wie in NavigationBar.scss
					line-height: pcD(28);		// .... wie in NavigationBar.scss
					// padding: pcD(5) pcD(16);
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		height: initial;

		.module {
			align-items: flex-start;
			margin: pcM(60) 0 0 0;
			padding-top: pcM(40);
			padding-bottom: pcM(40);
		}

		UL
		{
			width: 100%;

			&.horizontal-list 
			{
				flex-direction: column;
				align-items: center;

				LI {
					font-size: pcM(16);
					line-height: pcM(24);
					padding: pcM(12) pcM(20);
				}
			}
		}
	}
}
