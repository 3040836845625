@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";


.NavigationBar 
{
	//######################################################
	$anim_duration: 0.25;
	//######################################################

	z-index: 9999999;
	width: 100%;
	position: fixed;
	top: 0;
	transition: 
		top $navBar_animation_duration, 
		background-color $navBar_animation_duration;
	// height: $navBar_height+px;	... siehe in .menu-header

	box-shadow: 0px 0px 12px 3px rgba(0,0,0,0.42);
	-webkit-box-shadow: 0px 0px 12px 3px rgba(0,0,0,0.42);
	-moz-box-shadow: 0px 0px 12px 3px rgba(0,0,0,0.42);

	background: $navBar_bg;

	// override global class .module
	.module {
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 0 36px;
	}

	.module 
	{
		.menu-container
		{
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			.menu-header 
			{
				height: $navBar_height+px;
				//
				display: flex;
				width: 100%;
				// height: 100%;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				A 
				{
					width: 100px;
					min-width: 100px;

					IMG {
						&.db-logo { 
							width: 72px; 
							// width: 60px; 
						}
					}
				}

				UL
				{
					&.horizontal-list 
					{
						margin: 0;
						padding: 0;
						list-style: none;
						display: flex;
						flex-direction: row;
						align-items: center;

						LI 
						{
							padding: 5px 27px;
							&:first-child { padding-left: 0; }
							&:last-child { padding-right: 0; }

							A, BUTTON
							{
								@include FONT-REGULAR();
								color: #000;

								&.MenuItem {
									font-family: $font-sans-bold;
								}
								&:hover {
									color: $color-red;
									text-decoration: none;
								}
							}
						}
					}
				}

				.contact-item {
					width: 224px;
					min-width: 224px;

					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-end;

					A, BUTTON
					{
						@include FONT-REGULAR();
						color: $color-red;

						&.MenuItem {
							font-family: $font-sans-bold;
						}
						&:hover {
							color: $color-red;
							text-decoration: none;
						}
					}
				}

				.mobile-menu-button { display: none; }
			}

			.menu-body 
			{ 
				display: none;
			}
		}
	}

	//############################################################################## responsive
	@include breakpoint($desktop) 
	{
		box-shadow: 0px 0px pcD(12) pcD(3) rgba(0,0,0,0.42);
		-webkit-box-shadow: 0px 0px pcD(12) pcD(3) rgba(0,0,0,0.42);
		-moz-box-shadow: 0px 0px pcD(12) pcD(3) rgba(0,0,0,0.42);

		.module 
		{
			// background: #00f;
			// padding: 0 pcD(36);
			padding: 0 pcD(36);

			.menu-container
			{
				.menu-header 
				{
					height: pcD($navBar_height);
				}
			}
		}
	}

	@include breakpoint($tablet) 
	{
		.module 
		{
			// background: #0f0;
			padding: 0 pcD(20);

			.menu-container
			{
				.menu-header 
				{
					A 
					{
						width: pcD(100);
						min-width: pcD(100);

						IMG {
							&.db-logo { 
								width: pcD(72); 
								transform: translateY(-50%);
    							top: 50%;
    							position: absolute;
							}
						}
					}

					UL
					{
						&.horizontal-list 
						{
							LI 
							{
								padding: pcD(5) pcD(27);

								A, BUTTON
								{
									// @include FONT-REGULAR(); ... nicht einschalten, sonst ist text-color o.ä. nicht mehr richtig
									font-size: pcD(16);
									line-height: pcD(24);
								}
							}
						}
					}

					.contact-item 
					{
						width: pcD(224);
						min-width: pcD(224);

						A, BUTTON
						{
							// @include FONT-REGULAR(); ... nicht einschalten, sonst ist text-color o.ä. nicht mehr richtig
							font-size: pcD(16);
							line-height: pcD(24);
						}
					}
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		.module 
		{
			// background: #fc0;

			.menu-container
			{
				.menu-header 
				{
					UL
					{
						&.horizontal-list 
						{
							LI 
							{
								padding: pcD(5) pcD(27);

								A, BUTTON
								{
									// @include FONT-REGULAR(); ... nicht einschalten, sonst ist text-color o.ä. nicht mehr richtig
									font-size: pcD(20);
									line-height: pcD(28);
								}
							}
						}
					}

					.contact-item 
					{
						width: pcD(260);
						min-width: pcD(260);

						A, BUTTON
						{
							// @include FONT-REGULAR(); ... nicht einschalten, sonst ist text-color o.ä. nicht mehr richtig
							font-size: pcD(20);
							line-height: pcD(28);
						}
					}
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		// height: pcM($navBar_mobile_height);	... siehe in .menu-header

		.module 
		{
			// background: #f90;
			padding: 0;
			// padding: 0 0 0 pcM(16);		// left=10 statt 20, weil Menü-Button ein Padding von 10px hat

			&.menu--open
			{
				background: $mobile_nav_bg;
				// height: 100vh;	... wird animiert
				animation: kf_open_anim $anim_duration+s ease-out 0s 1 forwards;

				.db-logo { display: none; }
				.contact-button { display: none; }
				.cta 
				{
					.open { display: none !important; }
					.close { display: block !important; }
				}
				.menu-body 
				{ 
					display: block; 
					animation: kf_fadeIn_anim $anim_duration+s ease-out 0s 1 forwards;
				}
			}

			&.close--anim
			{
				animation: kf_close_anim $anim_duration+s ease-out 0s 1 forwards;

				.menu-body 
				{ 
					animation: kf_fadeOut_anim $anim_duration+s ease-out 0s 1 forwards;
				}
			}

			.menu-container
			{
				.menu-header 
				{
					height: pcM($navBar_mobile_height);
					padding: 0 0 0 pcM(16);		// left=10 statt 20, weil Menü-Button ein Padding von 10px hat

					A 
					{
						IMG 
						{
							&.db-logo 
							{ 
								width: auto;
								height: pcM(28);
							}
						}
					}

					UL,
					.contact-item
					{
						display: none !important;
					}

					.mobile-menu-button 
					{ 
						padding: pcM(10) pcM(6);
						display: flex;
						flex-direction: row;
						align-content: center;
						flex-wrap: nowrap;

						.contact-button
						{
							cursor: pointer;
							background: transparent;
							border: 0;

							&:focus { outline: none; }

							// SVG { ... hier die Klasse ansprechen und nicht den Tag. Auf dem iPhone greift die Klasse nicht, wenn man es auf dem Tag anwendet (getestet auf: Safari, Firefox, Chrome, Opera und Edge)
							.contact-icon {
								padding: pcM(10);
								width: auto;
								height: pcM(48);		// 48 = 10 (top-padding) + 28 (height) + 10 (bottom-padding)
							}
						}

						.cta
						{
							cursor: pointer;
							background: transparent;
							border: 0;

							&:focus { outline: none; }

							.open { display: block; }
							.close { display: none; }

							// IMG,
							// SVG { ... hier die Klasse ansprechen und nicht den Tag. Auf dem iPhone greift die Klasse nicht, wenn man es auf dem Tag anwendet (getestet auf: Safari, Firefox, Chrome, Opera und Edge)
							.open,
							.close {
								padding: pcM(10);
								width: auto;
								height: pcM(36);
							}
						}
					}
				}

				.menu-body 
				{ 
					width: 100%;
					// height: calc(100% - $navBar_height+px);
					height: calc(100% - 75px - 13vh);		// ... in calc(...) können SCSS-Variablen nicht verarbeutet werden; 75 = navBar height, 13hv ~ bottom nav bar on mobile

					UL
					{
						&.vertical-list 
						{
							// height: calc(100vh - $navBar_height+px);
							height: calc(100vh - 75px - 13vh);		// ... in calc(...) können SCSS-Variablen nicht verarbeitet werden; 75 = navBar height, 13hv ~ bottom nav bar on mobile
							margin: 0;
							padding: 0;
							list-style: none;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							LI 
							{
								padding: pcM_height(30) pcM(20);
								text-align: center;

								A, BUTTON 
								{
									color: #fff;
									height: fit-content;

									&.MenuItem {
										font-family: $font-sans;
										font-size: pcM(23);
										line-height: pcM(30);
									}
									&:hover {
										color: $color-red;
										text-decoration: none;
									}

									// SVG, ... hier die Klasse ansprechen und nicht den Tag. Auf dem iPhone greift die Klasse nicht, wenn man es auf dem Tag anwendet (getestet auf: Safari, Firefox, Chrome, Opera und Edge)
									.icon {
										margin: 0 pcM(20) 0 0;
										width: auto;
										height: pcM(28);

										path { fill: #fff; }
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@keyframes kf_open_anim
{
	0% { height: $navBar_mobile_height+px; }
	100% { height: 100vh; }
}
@keyframes kf_close_anim
{
	0% { 
		height: 100vh; 
		background: $mobile_nav_bg;
	}
	100% { 
		height: $navBar_mobile_height+px; 
		background: transparent;
	}
}

@keyframes kf_fadeIn_anim
{
	0% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes kf_fadeOut_anim
{
	0% { opacity: 1; }
	100% { opacity: 0; }
}