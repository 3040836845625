
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

A, BUTTON
{
	&.MenuItem  
	{ 
		color: #fff;
		text-decoration: none;
		border: 0;
		outline: none;
		background: transparent;
		cursor: pointer;

		&.withIcon {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: center;
		}

		SVG, IMG,		// ACHTUNG! Safari scheint IMG und SVG zu ignorieren, deshlab auch die Klasse vom IMG/SVG angeben
		.icon { 
			margin: 0 25px 0 0;
			width: auto; 
		}
		&.withIcon, IMG, SVG, .icon { height: 28px; }		// ACHTUNG! Safari scheint IMG und SVG zu ignorieren, deshlab auch die Klasse vom IMG/SVG angeben

		&:hover {
			// font-family: $font-sans-bold;
    		text-decoration: underline;
		}
	}
}

//############################################################################## responsive
@include breakpoint($desktop) 
{
	A, BUTTON
	{
		&.MenuItem  
		{
			IMG, SVG, 		// ACHTUNG! Safari scheint IMG und SVG zu ignorieren, deshlab auch die Klasse vom IMG/SVG angeben
			.icon { 
				margin: 0 pcD(25) 0 0;
			}
			&.withIcon, IMG, SVG, .icon { height: pcD(28); }		// ACHTUNG! Safari scheint IMG und SVG zu ignorieren, deshlab auch die Klasse vom IMG/SVG angeben
		}
	}
}