@import "./fontfaces.scss";
@import "./colors.scss";

$font-fallback: "Helvetica Neue",Helvetica,Arial,sans-serif;

$font-head: "DBHead-Regular",$font-fallback;
$font-head-black: "DBHead-Black",$font-fallback;
$font-sans: "DBSans-Regular",$font-fallback;
$font-sans-bold: "DBSans-Bold",$font-fallback;

@mixin FONT-H1() {
	font-family: $font-head-black;
	font-size: 48px;
	color: $color_title;
	// letter-spacing: 0px;
	line-height: 64px;
}

@mixin FONT-H2() {
	font-family: $font-head-black;
	font-size: 48px;
	color: $color_title;
	letter-spacing: 0px;
	line-height: 64px;
}

@mixin FONT-H3() {
	font-family: $font-head-black;
	font-size: 34px;
	color: $color_title;
	letter-spacing: 0px;
	line-height: 40px;
}

@mixin FONT-H6() {
	font-family: $font-head;
	font-size: 24px;
	color: $color_text;
	letter-spacing: 0px;
	line-height: 34px;
}

@mixin FONT-REGULAR() {
	font-family: $font-sans;
	font-size: 16px;
	color: $color_text;
	letter-spacing: 0px;
	line-height: 24px;
}

@mixin FONT-BOLD() {
	font-family: $font-sans-bold;
	font-size: 16px;
	color: $color_text;
	letter-spacing: 0px;
	line-height: 24px;
}

@mixin FONT-DBHead-BlackItalic() {
	font-family: "DBHead",$font-fallback;
	font-weight: 900; 
	font-style: italic;
}

@mixin FONT-DBSans-Italic() {
	font-family: "DBSans",$font-fallback;
	font-weight: normal; 
	font-style: italic;
}



@mixin default_p() {
	// p, div { 
	@include FONT-REGULAR; 

	b, strong { @include FONT-BOLD; }
	// }
}
