@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

.BoldNumberItem 
{
	display: flex;
	flex-direction: column;
	width: 33%;
	max-width: 392px;

	margin: 0 30px;
	padding: 0 24px;

	// background: rgb(224, 168, 0);		// debug color

	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}

	.icon 
	{
		width: 100%;
		max-height: 78px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		img { 
			width: auto; 
			height: 100%;
		}
	}

	.numValue 
	{
		margin: 21px 0 0 0;
		font-family: $font-head-black;
		font-size: 34px;
		line-height: 34px;
		color: #000;
		text-align: center;
	}

	.title 
	{
		@include FONT-REGULAR();
		font-weight: bold;
		text-align: center;
		margin: 10px 0 0 0;
	}

	.subline,
	.body
	{
		@include FONT-REGULAR();
		text-align: center;
		margin: 0;
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		// margin: 58px 30px 0 !important;
		// padding: 0 24px !important;
		// width: 70vw;

		// &:first-child {
		// 	margin-top: 0 !important;
		// }

		max-width: pcD(392);
		margin: 0 pcD(30);
		padding: 0 pcD(24);

		.icon 
		{
			max-height: pcD(78);
		}

		.numValue 
		{
			margin: pcD(21) 0 0 0;
			font-size: pcD(34);
			line-height: pcD(34);
		}

		.title 
		{
			// @include FONT-REGULAR(); ... von hier kommen font-size und line-height
			font-size: pcD(16);
			line-height: pcD(24);
			margin: pcD(10) 0 0 0;
		}

		.subline,
		.body
		{
			// @include FONT-REGULAR(); ... von hier kommen font-size und line-height
			font-size: pcD(16);
			line-height: pcD(24);
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		// background: #fc0;
		max-width: pcD(392 * $tablet_2nd_factor);
		margin: 0 pcD(30 * $tablet_2nd_factor);
		padding: 0 pcD(24 * $tablet_2nd_factor);

		.icon 
		{
			max-height: pcD(78 * $tablet_2nd_factor);
		}

		.numValue 
		{
			margin: pcD(21 * $tablet_2nd_factor) 0 0 0;
			font-size: pcD(34 * $tablet_2nd_factor);
			line-height: pcD(34 * $tablet_2nd_factor);
		}

		.title 
		{
			// @include FONT-REGULAR(); ... von hier kommen font-size und line-height
			font-size: pcD(16 * $tablet_2nd_factor);
			line-height: pcD(24 * $tablet_2nd_factor);
			margin: pcD(10 * $tablet_2nd_factor) 0 0 0;
		}

		.subline,
		.body
		{
			// @include FONT-REGULAR(); ... von hier kommen font-size und line-height
			font-size: pcD(16 * $tablet_2nd_factor);
			line-height: pcD(24 * $tablet_2nd_factor);
		}
	}

	@include breakpoint($mobile) 
	{
		width: 100%;
		max-width: none;
		margin: pcM(28) 0 !important;
		padding: 0 pcM(20) !important;
		
		.icon 
		{
			width: 100%;
			max-height: pcM(70);
	
			img { 
				width: auto; 
				height: 100%;
			}
		}

		.numValue 
		{
			margin: pcM(24) 0 0 0;	
			font-size: pcM(24);
			line-height: pcM(28);
		}

		.title 
		{
			margin: pcM(10) 0 0 0;
		}

		.title, .subline
		{
			font-size: pcM(16);
			line-height: pcM(24);
		}
	}
}
