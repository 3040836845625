
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../../scss/background.scss";

@import "./HighlightsVars.scss";

.HighlightItem
{
	//-----------------------------------------
	$internal_margin: 24;
	//-----------------------------------------

	width: 504px;
	margin: 0 $child_margin_half+px;
	display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

	.img-container-wrapper
	{
		IMG 
		{ 
			display: block; 

			&.image {
				width: auto;
				max-width: 444px;
				height: $img_max_height+px;

				box-shadow: 0 25px 50px rgba(0, 0, 0, 0.20); 
				-webkit-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.20); 
				-moz-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.20); 
			}
		}
	}

	.text-container-wrapper 
	{
		width: 100%;

		.text-container {
			width: 100%;
			margin: 50px 0 0 0;
			@include FONT-REGULAR;
			color: #000;
			text-align: center;
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		width: pcD(504);
		margin: 0 pcD($child_margin_half);

		.img-container-wrapper
		{
			IMG 
			{ 
				&.image {
					max-width: pcD(444);
					height: pcD($img_max_height);

					box-shadow: 0 pcD(25) pcD(50) rgba(0, 0, 0, 0.20); 
					-webkit-box-shadow: 0 pcD(25) pcD(50) rgba(0, 0, 0, 0.20); 
					-moz-box-shadow: 0 pcD(25) pcD(50) rgba(0, 0, 0, 0.20); 
				}
			}
		}

		.text-container-wrapper 
		{
			.text-container {
				margin: pcD(50) 0 0 0;
				//@include FONT-REGULAR;
				font-size: pcD(16);
				line-height: pcD(24);
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		width: pcD(504 * $tablet_2nd_factor);
		margin: 0 pcD($child_margin_half * $tablet_2nd_factor);

		.img-container-wrapper
		{
			IMG 
			{ 
				&.image {
					max-width: pcD(444 * $tablet_2nd_factor);
					height: pcD($img_max_height * $tablet_2nd_factor);
				}
			}
		}

		.text-container-wrapper 
		{
			.text-container {
				margin: pcD(50 * $tablet_2nd_factor) 0 0 0;
				//@include FONT-REGULAR;
				font-size: pcD(16 * $tablet_2nd_factor);
				line-height: pcD(24 * $tablet_2nd_factor);
			}
		}
	}

	@include breakpoint($mobile) 
	{

		width: 90vw;

		.img-container-wrapper
		{
			IMG 
			{	
				&.image {
					width: 75vw;
					max-width: pcM(400);
					height: auto;
	
					box-shadow: 0 pcM(12) pcM(25) rgba(0, 0, 0, 0.20); 
					-webkit-box-shadow: 0 pcM(12) pcM(25) rgba(0, 0, 0, 0.20); 
					-moz-box-shadow: 0 pcM(12) pcM(25) rgba(0, 0, 0, 0.20); 
				}
			}
		}
	
		.text-container-wrapper 
		{
			width: 100%;
	
			.text-container {
				margin: pcM(24) 0 0 0;
				font-size: pcM(16);
				line-height: pcM(24);
			}
		}
	}
}