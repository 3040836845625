@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/material-ui.scss";

.ContainerCols
{
	.main-container 
	{
		// width: 100%;
		background: #fff;
		border-radius: 5px;
		padding: 20px 20px;
		margin: 73px 59px;

		box-shadow: 0px 15px 80px 0px rgba(0,0,0,0.25);		// edge fix - 15px statt 25px
		-webkit-box-shadow: 0px 25px 80px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 25px 80px 0px rgba(0,0,0,0.25);

		.title-container 
		{
			.subtitle-container { padding-bottom: 0; }
		}

		.content
		{
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: center;
			justify-content: space-between;
			align-items: flex-start;
			padding: 48px 0 85px 0;
		}
	}

	//------------------------------------------------------------------------ animation
	.main-container 
	{
		.content
		{
			//---------------------------
			$item_anim_delay: 0.20;
			//---------------------------
			.BoldNumberItem[data-scroll]
			{
				transition: opacity 1s, transform 1s;
				transform: translateY(+50px);
				opacity: 0;

				@for $i from 1 to 10
				{
					&:nth-child(#{$i}) {
						transition-delay: (($i - 1) * $item_anim_delay)+s;	// $i := 0..(n-1) >> delay mit 0 Sekunden anfangen
						// transition-delay: ($i * $item_anim_delay)+s;		// $1 ::= 1..n >> delay mit <item_anim_delay> Sekunden anfangen
					}
				}
			}
		}
	}
	.main-container 
	{
		.content
		{
			.BoldNumberItem[data-scroll=in]
			{
				opacity: 1;
				transform: translateY(0px);
			}
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.main-container 
		{
			// border-radius: pcD(5); ... nicht nötig
			padding: pcD(20) pcD(20);
			margin: pcD(73) pcD(59);

			box-shadow: 0px pcD(15) pcD(80) 0px rgba(0,0,0,0.25);	// edge fix - 15px statt 25px
			-webkit-box-shadow: 0px pcD(25) pcD(80) 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px pcD(25) pcD(80) 0px rgba(0,0,0,0.25);

			.content
			{
				padding: pcD(48) 0 pcD(85) 0;
			}
		}
	}

	@include breakpoint($mobile) 
	{		
		.main-container
		{
			margin: pcM(60) 0;
			box-shadow: none;		// edge fix - 15px statt 25px
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			
			.content 
			{
				flex-wrap: wrap;
				flex-direction: column;
			}
		} 
	}
}