
// @import "../../scss/global.scss"; ... wird in index.js importiert, nur 1x !!!
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";

.Impressum {
	@include FONT-H1;
	margin: 50px 0 0 0;
	text-align: center;
}