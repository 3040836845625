@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/material-ui.scss";

.TextImage2Cols
{
	//-----------------------------------------
	$desktop_LR_width_d: 570;
	$desktop_title_padding_d: 37;
	//
	$desktop_LR_width_m: 570;
	$desktop_title_padding_m: 37;
	//-----------------------------------------

	.main-container 
	{
		padding: 0;
		margin: 56px 260px;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: flex-start;
		align-items: flex-start;

		&.img-right { flex-direction: row-reverse; }

		.mobile-title-container { display: none; }

		.img-container 
		{
			margin-top: 5px;
			max-width: $desktop_LR_width_d+px;
			width: $desktop_LR_width_d+px;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;

			IMG {
				display: block;
				width: auto;
				height: 328px;
			}
		}

		.content-container 
		{
			width: $desktop_LR_width_d+px;
			padding: 0 37px;

			.Title 
			{
				width: 100%;

				&.embedded {
					align-items: flex-start;
				}

				.title-container
				{
					H2 {
						margin-top: -10px;
						padding: 0 0 $desktop_title_padding_d+px 0;
						text-align: left;
						// line-height: 51px;

						&:after { margin: $desktop_title_padding_d+px 0 0 0; }
					}
				}

				.subtitle-container { 
					display: none;
				}
			}

			.content
			{
				width: 100%;

				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-content: flex-start;
				justify-content: flex-start;
				align-items: flex-start;

				.subline {
					@include FONT-BOLD;
					color: #000;
				}

				.body {
					@include FONT-REGULAR;
					color: #000;
				}

				.cta 
				{
					// width: 278px;
					height: 56px;
					box-shadow: none;
					background: $color_red;
					margin: 28px 0 0 0;
	
					// &.Mui-disabled { background: #675dc4; }
	
					// &:hover { background: lighten($color_red, 10); }
					&:hover { background: darken($color_red, 5); }

					.MuiButton-label {
						@include FONT-BOLD;
						color: #fff;
						font-size: 16px;
						text-transform: none;
						letter-spacing: 0;
					}
				}
			}
		}
	}

	//------------------------------------------------------------------------ animation
	.img-container[data-scroll] {
		transition: opacity 1s, transform 1s;
		transform: translateY(+50px);
		opacity: 0;
	}
	.img-container[data-scroll=in]
	{
		opacity: 1;
		transform: translateY(0px);
	}

	.mobile-title-container[data-scroll]
	{
		transition: transform 1s;
		transform: scale(0);	
	}
	.mobile-title-container[data-scroll=in]
	{
		transform: scale(1);
	}

	//------------------------------------
	$anim_text_title_delay: 0.5;
	$anim_text_subline_delay: 0.15;
	$anim_text_body_delay: 0.15;
	$anim_text_cta_delay: 0.15;
	//------------------------------------
	.content-container[data-scroll] 
	{
		.desktop-title-container {
			transition: transform 1s;
			transition-delay: $anim_text_title_delay;
			transform: scale(0);
		}
		.content 
		{
			.subline,
			.body,
			.cta {
				transition: opacity 1s, transform 1s;
				transform: translateY(+50px);
				// transition-delay: 0.25s;
				opacity: 0;
			}
			.subline { transition-delay: ($anim_text_title_delay + $anim_text_subline_delay)+s; }
			.body { transition-delay: ($anim_text_title_delay + $anim_text_subline_delay + $anim_text_body_delay)+s; }
			.cta { transition-delay: ($anim_text_title_delay + $anim_text_subline_delay + $anim_text_body_delay + $anim_text_cta_delay)+s; }
		}
	}
	.content-container[data-scroll=in]
	{
		.desktop-title-container {
			transform: scale(1);	
		}
		.content 
		{
			.subline,
			.body,
			.cta {
				opacity: 1;
				transform: translateY(0px);
			}
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.main-container 
		{
			margin: pcD(56) pcD(260);

			.img-container 
			{
				// margin-top: pcD(5);
				max-width: pcD($desktop_LR_width_d);
				width: pcD($desktop_LR_width_d);

				IMG {
					height: pcD(328);
				}
			}

			.content-container 
			{
				width: pcD($desktop_LR_width_d);
				padding: 0 pcD(37);
				// padding: 0 pcD(25) 0 pcD(37);

				.Title 
				{
					.title-container
					{
						H2 {
							margin-top: pcD(-10);
							padding: 0 0 pcD($desktop_title_padding_d) 0;
							font-size: pcD(48);
							// line-height: pcD(51);

							&:after { margin: pcD($desktop_title_padding_d) 0 0 0; }
						}
					}
				}

				.content
				{
					.subline {
						// @include FONT-BOLD;
						font-size: pcD(16);
						line-height: pcD(24);
					}

					.body {
						// @include FONT-REGULAR;
						font-size: pcD(16);
						line-height: pcD(24);
					}

					.cta 
					{
						// width: 278px;
						height: pcD(56);
						margin: pcD(28) 0 0 0;
		
						.MuiButton-label {
							// @include FONT-BOLD;
							font-size: pcD(16);
							line-height: pcD(24);
						}
					}
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		.main-container 
		{
			margin: pcD(56 * $tablet_2nd_factor) pcD(140 * $tablet_2nd_factor);

			.img-container 
			{
				// margin-top: pcD(5 * $tablet_2nd_factor);
				max-width: pcD($desktop_LR_width_m * $tablet_2nd_factor);
				width: pcD($desktop_LR_width_m * $tablet_2nd_factor);

				IMG {
					height: pcD(328 * $tablet_2nd_factor);
				}
			}

			.content-container 
			{
				width: pcD($desktop_LR_width_m * $tablet_2nd_factor);
				padding: 0 pcD(20 * $tablet_2nd_factor) 0 pcD(37 * $tablet_2nd_factor);
				// padding: 0 pcD(25 * $tablet_2nd_factor) 0 pcD(37 * $tablet_2nd_factor);

				.Title 
				{
					.title-container
					{
						H2 {
							// margin-top: pcD(-20 * $tablet_2nd_factor);
							margin-top: 0;
							padding: 0 0 pcD($desktop_title_padding_m * $tablet_2nd_factor) 0;
							font-size: pcD(48 * $tablet_2nd_factor);
							line-height: pcD(58 * $tablet_2nd_factor);

							&:after { margin: pcD($desktop_title_padding_m * $tablet_2nd_factor) 0 0 0; }
						}
					}
				}

				.content
				{
					.subline {
						// @include FONT-BOLD;
						font-size: pcD(16 * $tablet_2nd_factor);
						line-height: pcD(24 * $tablet_2nd_factor);
					}

					.body {
						// @include FONT-REGULAR;
						font-size: pcD(16 * $tablet_2nd_factor);
						line-height: pcD(24 * $tablet_2nd_factor);
					}

					.cta 
					{
						// width: 278px;
						height: pcD(56 * $tablet_2nd_factor);
						margin: pcD(28 * $tablet_2nd_factor) 0 0 0;
		
						.MuiButton-label {
							// @include FONT-BOLD;
							font-size: pcD(16 * $tablet_2nd_factor);
							line-height: pcD(24 * $tablet_2nd_factor);
						}
					}
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		.main-container 
		{
			flex-direction: column;
			margin: pcM(56) pcM(20) pcM(60);

			.mobile-title-container 
			{ 
				display: block; 
				width: 100%;

				.Title
				{
					.title-container
					{
						H2 
						{
							margin: 0;
							padding-bottom: pcM(25);
							font-size: pcM(28);
							line-height: pcM(36);

							&:after {
								margin-top: pcM(14);
							}
						}
					}

					.subtitle-container { display: none; }
				}
			}

			.img-container
			{
				margin-top: 0;
				max-width: initial;
				width: 100%;

				IMG {
					width: 100%;
					height: auto;
				}
			}

			.content-container 
			{
				width: 100%;
				padding: pcM(25) 0 0 0;

				.desktop-title-container { display: none }

				.content
				{
					.subline,
					.body {
						font-size: pcM(16);
						line-height: pcM(24);
					}

					.cta {
						width: 100%;
						height: pcM(56);
						margin-top: pcM(30);

						.MuiButton-label {
							// @include FONT-BOLD;
							font-size: pcM(16);
							line-height: pcM(24);
						}
					}
				}
			}
		}
	}
}