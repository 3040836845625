@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/material-ui.scss";

.ContactCols
{
	.module {
		margin: 0 auto 25px auto;
	}

	.main-container 
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: center;	//space-around;
		align-items: flex-start;
	}

	//------------------------------------------------------------------------ animation
	.main-container
	{
		//---------------------------
		$item_anim_delay: 0.35;
		//---------------------------
		.ContactItem[data-scroll] {
			transition: opacity 1s, transform 1s;
			transform: translateY(+50px);
			// transition-delay: 0.25s;
			opacity: 0;

			&:nth-child(2n) {
				transition-delay: $item_anim_delay+s;	// Verzögerung für jeden 2. Child
			}
		}
	}

	.main-container
	{
		.ContactItem[data-scroll=in]
		{
			opacity: 1;
			transform: translateY(0px);
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.module {
			margin-bottom: pcD(25);
		}
	}

	@include breakpoint($mobile) 
	{
		.module {
			margin-bottom: pcM(67);
		}
		
		.main-container 
		{
			flex-direction: column;
			align-items: center;
			align-content: center;
		}
	}
}