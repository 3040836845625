
@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

@import "./TestimonialVars.scss";

.TestimonialsSlider 
{
	color: #fff;

	// override global class .module
	.module {
		height: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 0 56px 0;
	}

	.root-container { width: 100%; }

	.slick-slider 
	{
		width: 100%;
		overflow: hidden;

		.slick-track 
		{
			display: flex;
			flex-direction: row;

			.slick-slide 
			{
				display: flex;
				flex-direction: row;
				justify-content: center;

				.slide
				{
					max-width: (965 + (2 * $child_margin_half))+px;		// 2*20 margin vom child left + right
					display: flex;
					flex-direction: row;
					justify-content: center;
					flex-wrap: wrap;
				}
			}
		}
	}

	.slick-dots 
	{
		display: block;
		position: static;
		bottom: auto;
		list-style: none;
		text-align: center;
		margin: 0;
		padding: 0;

		li 
		{
			pointer-events: all;
			margin: 0 0 0 0;
			position: relative;
			display: inline-block;

			&:last-child {
				margin-right: 0;
			}

			button 
			{
				font-size: 0;
				line-height: 0;
				display: block;
				color: transparent;
				border: 0;
				padding: 0 6px;

				// ACHTUNG! button width & height auf 8x8 gestellt, der button wird aber als 10x10 dargestellt!
				width: 8px;
				height: 8px;
				// background-size: cover;
				background: transparent left top / 100% 100% no-repeat url("../../assets/dot_40alpha.svg");
				outline: none;

				// &:before {
				// 	width: 70px;
				// 	height: 70px;
				// 	opacity: 1 !important;
				// 	content: "";
				// }

				&:hover {
					background-image: url("../../assets/dot.svg");
					background-size: 100% 100%;
					width: 8px;
					height: 8px;
				}
			}

			&.slick-active 
			{
				button {
					background-image: url("../../assets/dot_active.svg");
					background-size: 100% 100%;
					width: 8px;
					height: 8px;
				}
			}
		}
	}

	//------------------------------------------------------------------------ animation
	.root-container[data-scroll]
	{
		//---------------------------
		$item_anim_delay: 0.15;
		//---------------------------
		.TestimonialItem
		{
			transition: opacity 1s, transform 1s;
			transform: translateY(+50px);
			opacity: 0;

			@for $i from 1 to 4
			{
				&:nth-child(#{$i}) {
					transition-delay: (($i - 1) * $item_anim_delay)+s;	// $i := 0..(n-1) >> delay mit 0 Sekunden anfangen
					// transition-delay: ($i * $item_anim_delay)+s;		// $1 ::= 1..n >> delay mit <item_anim_delay> Sekunden anfangen
				}
			}
		}
	}
	.root-container[data-scroll=in]
	{
		.TestimonialItem
		{
			opacity: 1;
			transform: translateY(0px);
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.module {
			padding: 0 0 pcD(56) 0;
		}
		
		.slick-slider 
		{
			.slick-track 
			{
				.slick-slide 
				{
					.slide
					{
						max-width: pcD(965 + (2 * $child_margin_half));		// 2*20 margin vom child left + right
					}
				}
			}
		}

		.slick-dots 
		{
			li 
			{
				button 
				{
					padding: 0 pcD(6);
					width: pcD(8);
					height: pcD(8);

					&:hover {
						width: pcD(8);
						height: pcD(8);
					}
				}

				&.slick-active 
				{
					button {
						width: pcD(8);
						height: pcD(8);
					}
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		// background: #ff0;

		.slick-slider 
		{
			.slick-track 
			{
				.slick-slide 
				{
					.slide
					{
						// background: #fc0;
						// max-width: pcD(965 + (2 * $child_margin_half));		// 2*20 margin vom child left + right
						max-width: pcD($max-page-width - (2 * $child_margin_half));
					}
				}
			}
		}

		.slick-dots 
		{
			li 
			{
				button 
				{
					padding: 0 pcD(6 * $tablet_2nd_factor);
					width: pcD(8 * $tablet_2nd_factor);
					height: pcD(8 * $tablet_2nd_factor);

					&:hover {
						width: pcD(8 * $tablet_2nd_factor);
						height: pcD(8 * $tablet_2nd_factor);
					}
				}

				&.slick-active 
				{
					button {
						width: pcD(8 * $tablet_2nd_factor);
						height: pcD(8 * $tablet_2nd_factor);
					}
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		.module {
			padding: 0 0 pcM(56) 0;
		}
		
		.slick-slider 
		{
			.slick-track 
			{
				.slick-slide 
				{
					.slide
					{
						max-width: pcM(965 + (2 * $child_margin_half));		// 2*20 margin vom child left + right
					}
				}
			}
		}

		.slick-dots 
		{
			li 
			{
				button 
				{
					padding: 0 pcM(6);
					width: pcM(8);
					height: pcM(8);

					&:hover {
						width: pcM(8);
						height: pcM(8);
					}
				}

				&.slick-active 
				{
					button {
						width: pcM(8);
						height: pcM(8);
					}
				}
			}
		}
	}
}
