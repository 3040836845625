@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
@import "../../scss/material-ui.scss";

.ContactForm
{
	.form-container
	{
		width: 100%;
		// border-radius: 5px;
		padding: 48px 20px 58px;

		// background: #fff2;		// debug only

		// -webkit-box-shadow: 0px 25px 99px 20px rgba(0,0,0,0.25); 
		// box-shadow: 0px 25px 99px 20px rgba(0,0,0,0.25);

		// &.padding-top-small { padding-top: ($default-padding-TB + $moduleWrapper-padding-small)+px; }
		// &.padding-top-medium { padding-top: ($default-padding-TB + $moduleWrapper-padding-medium)+px; }
		// &.padding-top-large { padding-top: ($default-padding-TB + $moduleWrapper-padding-large)+px; }
		// &.padding-bottom-small { padding-bottom: ($default-padding-TB + $moduleWrapper-padding-small)+px; }
		// &.padding-bottom-medium { padding-bottom: ($default-padding-TB + $moduleWrapper-padding-medium)+px; }
		// &.padding-bottom-large { padding-bottom: ($default-padding-TB + $moduleWrapper-padding-large)+px; }

		@include material-ui-4-white-bg();

		FORM 
		{
			width: 100%;
			max-width: map-get($breakpoints, "desktop");
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.name-email-container,
			.your_contact,
			.your_question,
			.button-container {
				width: 100%;
				max-width: 780px;
				margin: 0 0 20px 0;
			}

			.name-email-container 
			{
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;

				.your_name,
				.your_email {
					width: 370px;
				}
				.your_name { margin-right: 20px; }
			}

			.button-container {
				margin: 0;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}

			.cta {
				min-width: 180px;
				height: 56px;
				box-shadow: none;
				@include FONT-BOLD();
				text-transform: none;
				color: #fff;
				font-size: 16px;
				line-height: 24px;
				background: $color_red;
				letter-spacing: 0;

				&.Mui-disabled { background: #ff7f82; }

				&:hover { background: darken($color_red, 5); }
			}

			//-------------------------------------------------------------------- chrome autofill fix + autoComplete="off" im form-Tag (funktioniert nicht immer, aber schaded auch nicht)
			@include autofill-customisation()
		}
	}

	//------------------------------------------------------------------------ animation
	.name-email-container[data-scroll]
	{
		.your_name, 
		.your_email {
			transition: opacity 1s, transform 1s;
			transform: translateY(+50px);
			// transition-delay: 0.25s;
			opacity: 0;
		}
	}
	.your_contact[data-scroll],
	.your_question[data-scroll],
	.button-container[data-scroll]
	{
		transition: opacity 1s, transform 1s;
		transform: translateY(+50px);
		// transition-delay: 0.25s;
		opacity: 0;
	}

	.name-email-container[data-scroll=in]
	{
		.your_name,
		.your_email {
			opacity: 1;
			transform: translateY(0px);
		}
	}
	.your_contact[data-scroll=in],
	.your_question[data-scroll=in],
	.button-container[data-scroll=in]
	{
		opacity: 1;
		transform: translateY(0px);
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.form-container
		{
			padding: pcD(48) pcD(20) pcD(58);

			//-------------------------------------------- @include material-ui-4-white-bg(); - START
			.MuiInputLabel-filled {
				font-size: pcD(16);
				line-height: pcD(24);
			}
		
			.MuiFilledInput-input {
				font-size: pcD(16);
				line-height: pcD(24);
			}

			.MuiFormHelperText-root.Mui-error {
				font-size: pcD(12);
				margin-left: pcD(12);
				margin-right: pcD(12);
			}
			//-------------------------------------------- @include material-ui-4-white-bg(); - END

			FORM 
			{
				max-width: pcD($max-page-width);

				.name-email-container,
				.your_contact,
				.your_question,
				.button-container {
					max-width: pcD(780);
					margin: 0 0 pcD(20) 0;
				}

				.name-email-container 
				{
					.your_name,
					.your_email {
						width: pcD(370);
					}
					.your_name { margin-right: pcD(20); }
				}

				.cta {
					min-width: pcD(180);
					height: pcD(56);
					// @include FONT-BOLD();
					font-size: pcD(16);
					line-height: pcD(24);
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		.form-container
		{
			padding: pcD(48 * $tablet_2nd_factor) pcD(20 * $tablet_2nd_factor) pcD(58 * $tablet_2nd_factor);

			//-------------------------------------------- @include material-ui-4-white-bg(); - START
			.MuiInputLabel-filled {
				font-size: pcD(16 * $tablet_2nd_factor);
				line-height: pcD(24 * $tablet_2nd_factor);
			}
		
			.MuiFilledInput-input {
				font-size: pcD(16 * $tablet_2nd_factor);
				line-height: pcD(24 * $tablet_2nd_factor);
			}

			.MuiFormHelperText-root.Mui-error {
				font-size: pcD(12 * $tablet_2nd_factor);
				margin-left: pcD(12 * $tablet_2nd_factor);
				margin-right: pcD(12 * $tablet_2nd_factor);
			}
			//-------------------------------------------- @include material-ui-4-white-bg(); - END

			FORM 
			{
				max-width: pcD($max-page-width);

				.name-email-container,
				.your_contact,
				.your_question,
				.button-container {
					max-width: pcD(1036 * $tablet_2nd_factor);
					margin: 0 0 pcD(20 * $tablet_2nd_factor) 0;
				}

				.name-email-container 
				{
					.your_name,
					.your_email {
						width: pcD((1036 - 20) * $tablet_2nd_factor);
					}
					.your_name { margin-right: pcD(20 * $tablet_2nd_factor); }
				}

				.cta {
					min-width: pcD(180 * $tablet_2nd_factor);
					height: pcD(56 * $tablet_2nd_factor);
					// @include FONT-BOLD();
					font-size: pcD(16 * $tablet_2nd_factor);
					line-height: pcD(24 * $tablet_2nd_factor);
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		.form-container
		{
			padding: pcM(40) pcM(20) pcM(36);

			//-------------------------------------------- @include material-ui-4-white-bg(); - START
			.MuiInputLabel-filled {
				font-size: pcM(16);
				line-height: pcM(24);
			}
		
			.MuiFilledInput-input {
				font-size: pcM(16);
				line-height: pcM(24);
			}

			.MuiFormHelperText-root.Mui-error {
				font-size: pcM(12);
				margin-left: pcM(12);
				margin-right: pcM(12);
			}
			//-------------------------------------------- @include material-ui-4-white-bg(); - END

			FORM 
			{
				max-width: 100%;

				.name-email-container,
				.your_contact,
				.your_question,
				.button-container {
					max-width: 100%;
					width: 100%;
					margin: 0 0 pcM(25) 0;
				}

				.name-email-container 
				{
					flex-direction: column;

					.your_name,
					.your_email {
						width: 100%;
					}
					.your_name { 
						margin-right: 0; 
						margin-bottom: pcM(25);
					}
				}

				.cta {
					min-width: initial;
					width: 100%;
					height: pcM(56);
					font-size: pcM(16);
					line-height: pcM(24);
				}
			}
		}
	}
}
