
@import "../../scss/background.scss";
@import "../../scss/vars.scss";


.bg-wrapper {
	width: 100%;
	height: 100%;
}
// .module { background: #fc03; }

.bg-TL-BR {
	// background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
	background: url("../../assets/bg_TL_BR_d.png") 50% 0/3610px 2100px repeat-y border-box content-box;
}
.bg-TR-BL {
	// background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
	background: url("../../assets/bg_TR_BL_d.png") 50% 0/3610px 2100px repeat-y border-box content-box;
}
.bg-L {
	// background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
	background: url("../../assets/bg_L_d.png") 50% 0/3610px 601px no-repeat border-box content-box;
}
.bg-R {
	// background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
	background: url("../../assets/bg_R_d.png") 50% 0/3610px 601px no-repeat border-box content-box;
}

//############################################################################## responsive
@include breakpoint($tablet) 
{
	.bg-TL-BR {
		background: url("../../assets/bg_TL_BR_d.png") 50% 0/pcD(3610) pcD(2100) repeat-y border-box content-box;
	}
	.bg-TR-BL {
		background: url("../../assets/bg_TR_BL_d.png") 50% 0/pcD(3610) pcD(2100) repeat-y border-box content-box;
	}
	.bg-L {
		background: url("../../assets/bg_L_d.png") 50% 0/pcD(3610) pcD(601) no-repeat border-box content-box;
	}
	.bg-R {
		background: url("../../assets/bg_R_d.png") 50% 0/pcD(3610) pcD(601) no-repeat border-box content-box;
	}
}

@include breakpoint($mobile) 
{
	.bg-TL-BR {
		background: url("../../assets/bg_TL_BR_m.png") 50% 0/pcM(375) pcM(1671) repeat-y border-box content-box;
	}
	.bg-TR-BL {
		background: url("../../assets/bg_TR_BL_m.png") 50% 0/pcM(375) pcM(1671) repeat-y border-box content-box;
	}
	.bg-L {
		background: url("../../assets/bg_L_m.png") 50% 0/pcM(375) pcM(554) no-repeat border-box content-box;
	}
	.bg-R {
		background: url("../../assets/bg_R_m.png") 50% 0/pcM(375) pcM(554) no-repeat border-box content-box;
	}
}