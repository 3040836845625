@import "./fonts.scss";
@import "./colors.scss";

@mixin autofill-customisation()
{
	//-------------------------------------------------------------------- chrome autofill fix + autoComplete="off" im form-Tag (funktioniert nicht immer, aber schaded auch nicht)
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: #000;
		-webkit-box-shadow: none;
		-webkit-transition: background-color 50000s ease-in-out 0s;
		transition: background-color 50000s ease-in-out 0s;
	}
}

@mixin material-ui-4-white-bg()
{
	.MuiInputLabel-filled {
		font-family: $font-sans;
		color: $color-red;

		&.MuiInputLabel-shrink,
		&.Mui-focused {
			// color: rgba($color-red, 0.75);
			color: $color-red;
		}
	}

	.MuiInputBase-root {
		&.Mui-focused { background: rgba($color_inputFiledBG, 0.10) !important; }
	}

	.MuiFilledInput-input {
		// color: $color-red;
		color: #000;
		font-weight: bold;
		background: initial;		// fix für <Select>: wenn man einen Wert aus der DropDown-Liste ausgewählt hat, setzt Material-UI "background: rgba(0,0,0, 0.05)" und ich habe es nicht herausgefunden, wo dieser Wert herkommt
	}

	.MuiFilledInput-root {
		font-family: $font-sans;
		border-radius: 5px 5px 0 0;
		background: $color_inputFiledBG;
		&:hover {
			background: rgba($color_inputFiledBG, 0.10);
		}
	}

	.MuiSvgIcon-root {
		path {
			fill: $color-red;
		}
	}
	
	.MuiFilledInput-underline:hover:before {
		border-bottom: 1px solid $color_red;
	}
	.MuiFilledInput-underline:before {
		border-bottom: 1px solid rgba($color_red, 0.45);
	}
	.MuiFilledInput-underline:after {
		border-bottom: 2px solid $color_red;
	}

	.MuiFormHelperText-root.Mui-error {
		font-family: $font-sans-bold;
		font-size: 12px;
		color: $color_red;
		margin-left: 12px;
		margin-right: 12px;
	}
}

