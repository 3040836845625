@import "fonts.scss";
@import "vars.scss";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body, html {
	margin: 0;
	width: 100%;
	min-height: 100%;
	@include FONT-REGULAR;
	// background: url("../assets/bg-gradient.png") repeat;
	// background: url("../assets/blue-black-bg.png");		//, #000;
	background: #fff;
	background-size: 100% auto;
}

//############################################################################## custom line breaks
/*
br.mobile { content: ""; }
br.mobile:after { content: " "; }			........ Firefox ignoriert das :after im br-Tag !!!
@include breakpoint($mobile) 
{
	br.desktop { content: ""; }
	br.desktop:after { content: " "; }		........ Firefox ignoriert das :after im br-Tag !!!

	br.mobile { content: initial; }
	br.mobile:after { content: initial; }	........ Firefox ignoriert das :after im br-Tag !!!
}
*/
br.mobile { display: none; }
@include breakpoint($mobile) 
{
	br.desktop { display: none; }
	br.mobile { display: initial; }
}

//############################################################################## text defaults
h1, h2, h3, h4, h5, h6 {
	font-family: $font-head-black;
}

h1 { @include FONT-H1; }
h2 { @include FONT-H2; }
h3 { @include FONT-H3; }
h6 { @include FONT-H6; }

h1, h2 
{
	margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
	// margin: 180px 0 $title-margin-bottom+px 0;
	margin: 53px 0 $title-margin-bottom+px 0;		// ... mit stars-bg und subtitle
	text-align: center;

	&:after{
		content: " ";
		display: block;
		width: 60px;
		height: 6px;
		background-color: $color_red;
		border-radius: 10px;
		margin: 22px auto 0 auto;
	}
}


// .............. use @include default_p();
/*p, div { 
	@include FONT-REGULAR; 

	b, strong { @include FONT-BOLD; }
}*/

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//############################################################################## responsive
@include breakpoint($tablet) 
{
	h1, h2 
	{
		// margin: pcD(241) 0 pcD($title-margin-bottom) 0;
		// margin: pcD(180) 0 pcD($title-margin-bottom) 0;
		margin: pcD(53) 0 pcD($title-margin-bottom) 0;

		&:after{
			width: pcD(60);
			height: pcD(6);
			border-radius: pcD(10);
			margin: pcD(22) auto 0 auto;
		}
	}

	$T_add: 0;
	h1 { font-size: pcD(48+$T_add); line-height: pcD(64+$T_add); }
	h2 { font-size: pcD(48+$T_add); line-height: pcD(64+$T_add); }
	h3 { font-size: pcD(34+$T_add); line-height: pcD(40+$T_add); }
	h6 { font-size: pcD(24+$T_add); line-height: pcD(28+$T_add); }
}

@include breakpoint($mobile) 
{
	h1 { font-size: pcM(26); line-height: pcM(30); }
	h2 { font-size: pcM(26); line-height: pcM(30); }

	h1, h2 
	{
		&:after {
			margin: pcM(24) auto 0 auto;
			width: pcM(43);
			height: pcM(5);
		}
	}
}