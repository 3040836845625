
$desktop: 'desktop';
$tablet: 'tablet';
$tablet_2nd: 'tablet2';
$mobile: 'mobile';

$max-page-width: 1660;

$tablet_2nd_factor: 1.2;

$breakpoints: (
	$desktop:  $max-page-width+px,
	// $tablet: 960px,
	$tablet: $max-page-width+px,
	$tablet_2nd: 960px,
	// $mobile: 640px
	// $mobile: 768px
	$mobile: 540px,			// mobile ab Surface Duo 540x720
);

/*
@import "scss/vars.scss";
@include breakpoint($desktop) {
	...
}
*/

@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	}
}

@function zoomD($val)
{
	// @return $val * (16.0 / 22.0);
	@return $val;
}

@function pcD($val)
{
	// @return (zoomD($val) / zoomD($max-page-width) * 100)+vw;
	@return ($val / $max-page-width * 100)+vw;
}

@function pcM($val)
{
	// @return ($val / 1242 * 100)+vw;
	@return ($val * 100 / 375)+vw;
}

@function pcM_height($val)
{
	@return ($val * 100 / 722)+vh;
}

//------------------------------------------ title
$title-margin-bottom: 40;
$title-mobile-margin-bottom: 24;

//------------------------------------------ module margins & paddings
$module-margin-LF: 20;
//--------------------
// $mW_margin: round(241 - 180);
// $moduleWrapper-margin-small: $mW_margin + (97 * 1);
// $moduleWrapper-margin-medium: $mW_margin + (97 * 2);
// $moduleWrapper-margin-large: $mW_margin + (97 * 3);
$mW_margin: 73;
$moduleWrapper-margin-small: ($mW_margin * 0);
$moduleWrapper-margin-medium: ($mW_margin * 1);
$moduleWrapper-margin-large: ($mW_margin * 2);

$moduleWrapper-padding-small: 0;	//(170 * 1);
$moduleWrapper-padding-medium: 0;	//(97 * 2);
$moduleWrapper-padding-large: 0;	//(97 * 3);
//--------------------
$moduleWrapper-margin-mobile-small: (60 * 0);	//(60 * 1);
$moduleWrapper-margin-mobile-medium: (60 * 1);	//(60 * 2);
$moduleWrapper-margin-mobile-large: (60 * 2);	//(60 * 3);

$moduleWrapper-padding-mobile-small: 0;		//(90 * 1);
$moduleWrapper-padding-mobile-medium: 0;	//(60 * 2);
$moduleWrapper-padding-mobile-large: 0;		//(60 * 3);

//------------------------------------------ navigation bar
$navBar_height: 114;
$navBar_mobile_height: 75;					// ... bei einer Breite von 375px!
$navBar_animation_duration: 0.6s;
$navBar_bg: rgba(255,255,255, 1.00);
