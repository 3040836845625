
@import "../../scss/background.scss";
@import "../../scss/vars.scss";


.module
{
	display: flex;
	flex-direction: column;
}

.wrapper
{
	max-width: $max-page-width+px;
	margin: 0 auto;
	// background: #f90;
}


/*
.bg-white { 
	@include bg-type(white, null, false); 
	&.gradTopDown { @include bg-type(white, gradTopDown, false); }
	&.gradBottomUp { @include bg-type(white, gradBottomUp, false); }
}
.bg-transparent 
{ 
	@include bg-type(transparent, null, false);
	&.gradTopDown { @include bg-type(transparent, gradTopDown, false); }
	&.gradBottomUp { @include bg-type(transparent, gradBottomUp, false); }

	h1, h2, h3, h6 { color: #000; }
	p { color: #808080; }
}
.bg-grey { 
	@include bg-type(grey, null, false); 
	&.gradTopDown { @include bg-type(grey, gradTopDown, false); }
	&.gradBottomUp { @include bg-type(grey, gradBottomUp, false); }
}
*/
.bg-transparent 
{ 
	@include bg-type(transparent, null, false);

	h1, h2, h3, h6 { color: #000; }
	p { color: #000; }
}

.moduleWrapper-margin-top-small { padding-top: $moduleWrapper-margin-small+px; }
.moduleWrapper-margin-top-medium { padding-top: $moduleWrapper-margin-medium+px; }
.moduleWrapper-margin-top-large { padding-top: $moduleWrapper-margin-large+px; }
.moduleWrapper-margin-bottom-small { padding-bottom: $moduleWrapper-margin-small+px; }
.moduleWrapper-margin-bottom-medium { padding-bottom: $moduleWrapper-margin-medium+px; }
.moduleWrapper-margin-bottom-large { padding-bottom: $moduleWrapper-margin-large+px; }

.overBorder 
{
	&.moduleWrapper-margin-top-small { padding-top: 0; }
	&.moduleWrapper-margin-top-medium { padding-top: 0; }
	&.moduleWrapper-margin-top-large { padding-top: 0; }
	&.moduleWrapper-margin-bottom-small { padding-bottom: 0; }
	&.moduleWrapper-margin-bottom-medium { padding-bottom: 0; }
	&.moduleWrapper-margin-bottom-large { padding-bottom: 0; }
}

//############################################################################## responsive
/*
@include breakpoint($tablet) 
{
	.moduleWrapper-margin-top-small { padding-top: pcD($moduleWrapper-margin-small); }
	.moduleWrapper-margin-top-medium { padding-top: pcD($moduleWrapper-margin-medium); }
	.moduleWrapper-margin-top-large { padding-top: pcD($moduleWrapper-margin-large); }
	.moduleWrapper-margin-bottom-small { padding-bottom: pcD($moduleWrapper-margin-small); }
	.moduleWrapper-margin-bottom-medium { padding-bottom: pcD($moduleWrapper-margin-medium); }
	.moduleWrapper-margin-bottom-large { padding-bottom: pcD($moduleWrapper-margin-large); }
}
*/

// Fix für CTAElement, wenn overBorder und Fensterbreite <= 880px
// ... in so einem Fall wird das CTAElement um 56px höher, weil die EingabeBox und CTA nicht mehr nebeneinender angezeigt werden, sondern untereinander
@media (max-width: 880px) 
{
	//---------------------------------
	$ctaElemAdditionalHeight: 56;		// Angabe in px
	//---------------------------------
	// .moduleWrapper-margin-top-small { padding-top: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-small); }
	// .moduleWrapper-margin-top-medium { padding-top: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-medium); }
	// .moduleWrapper-margin-top-large { padding-top: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-large); }
	// .moduleWrapper-margin-bottom-small { padding-bottom: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-small); }
	// .moduleWrapper-margin-bottom-medium { padding-bottom: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-medium); }
	// .moduleWrapper-margin-bottom-large { padding-bottom: pcD($ctaElemAdditionalHeight + $moduleWrapper-margin-large); }
	.moduleWrapper-margin-top-small { padding-top: ($ctaElemAdditionalHeight + $moduleWrapper-margin-small)+px; }
	.moduleWrapper-margin-top-medium { padding-top: ($ctaElemAdditionalHeight + $moduleWrapper-margin-medium)+px; }
	.moduleWrapper-margin-top-large { padding-top: ($ctaElemAdditionalHeight + $moduleWrapper-margin-large)+px; }
	.moduleWrapper-margin-bottom-small { padding-bottom: ($ctaElemAdditionalHeight + $moduleWrapper-margin-small)+px; }
	.moduleWrapper-margin-bottom-medium { padding-bottom: ($ctaElemAdditionalHeight + $moduleWrapper-margin-medium)+px; }
	.moduleWrapper-margin-bottom-large { padding-bottom: ($ctaElemAdditionalHeight + $moduleWrapper-margin-large)+px; }
}

@include breakpoint($mobile) 
{
	/*
	.bg-white { 
		@include bg-type(white, null, true); 
		&.gradTopDown { @include bg-type(white, gradTopDown, true); }
		&.gradBottomUp { @include bg-type(white, gradBottomUp, true); }
	}
	.bg-transparent 
	{ 
		@include bg-type(transparent, null, true);
		&.gradTopDown { @include bg-type(transparent, gradTopDown, true); }
		&.gradBottomUp { @include bg-type(transparent, gradBottomUp, true); }
	
		h1, h2, h3, h6, p { color: #fff; }
	}
	.bg-grey { 
		@include bg-type(grey, null, true); 
		&.gradTopDown { @include bg-type(grey, gradTopDown, true); }
		&.gradBottomUp { @include bg-type(grey, gradBottomUp, true); }
	}
	*/

	.moduleWrapper-margin-top-small { padding-top: pcM($moduleWrapper-margin-mobile-small); }
	.moduleWrapper-margin-top-medium { padding-top: pcM($moduleWrapper-margin-mobile-medium); }
	.moduleWrapper-margin-top-large { padding-top: pcM($moduleWrapper-margin-mobile-large); }
	.moduleWrapper-margin-bottom-small { padding-bottom: pcM($moduleWrapper-margin-mobile-small); }
	.moduleWrapper-margin-bottom-medium { padding-bottom: pcM($moduleWrapper-margin-mobile-medium); }
	.moduleWrapper-margin-bottom-large { padding-bottom: pcM($moduleWrapper-margin-mobile-large); }
}