
@import "../../scss/fonts.scss";
@import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../../scss/background.scss";

@import "./TestimonialVars.scss";

.TestimonialItem
{
	//-----------------------------------------
	$internal_margin: 24;
	//-----------------------------------------

	max-width: 462px;
	margin: 0 $child_margin_half+px;
	padding: $internal_margin+px;
	display: flex;
	flex-direction: row;

	background: #fff;
	border: 1px solid #000;
	border-radius: 8px;
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.10); 
	-webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.10); 
	-moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.10); 

	&:nth-child(1), 
	&:nth-child(2)
	{
		margin-bottom: (2 * $child_margin_half)+px;
	}
	&:nth-child(3), 
	&:nth-child(4)
	{
		margin-bottom: 35px;
	}

	.img-container-wrapper
	{
		// width: 100px;			   ... bringt den IE11 durcheinander
		height: 100px;				// ... IE11 FIX
		margin: 0 $internal_margin+px 0 0;

		.image {
			max-width: 100px;
			width: auto;
			height: 100px;
			border-radius: 50%;
		}
	}

	.text-container-wrapper
	{
		width: 100%;

		.text-container
		{
			display: flex;
			flex-direction: column;

			.top-line 
			{
				display: flex;
				flex-direction: row;
				margin: 0 0 9px 0;

				.name,
				.position {
					@include FONT-REGULAR();
					font-size: 13px;
					line-height: 19px;
				}

				.name {
					color: #000;
					margin-right: 20px;
				}

				.position {
					color: #EC0016;
				}
			}

			.quote {
				@include FONT-REGULAR();
				font-size: 20px;
				line-height: 24px;
				color: #000;

				&::before {
					content: "„"
				}
				&::after {
					content: "“"
				}
			}
		}
	}

	.top-line-mobile
	{
		display: none;
	}

	.quote-mobile {
		display: none;
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		max-width: pcD(462);
		margin: 0 pcD($child_margin_half);
		padding: pcD($internal_margin);

		border-radius: pcD(8);
		box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 
		-webkit-box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 
		-moz-box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 

		&:nth-child(1), 
		&:nth-child(2)
		{
			margin-bottom: pcD(2 * $child_margin_half);
		}
		&:nth-child(3), 
		&:nth-child(4)
		{
			margin-bottom: pcD(35);
		}

		.img-container-wrapper
		{
			height: pcD(100);
			margin: 0 pcD($internal_margin) 0 0;

			.image {
				max-width: pcD(100);
				height: pcD(100);
			}
		}

		.text-container-wrapper
		{
			.text-container
			{
				.top-line 
				{
					margin: 0 0 pcD(9) 0;

					.name,
					.position {
						// @include FONT-REGULAR();
						font-size: pcD(13);
						line-height: pcD(19);
					}

					.name {
						margin-right: pcD(20);
					}
				}

				.quote {
					// @include FONT-REGULAR();
					font-size: pcD(20);
					line-height: pcD(24);
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		// max-width: pcD(462);
		max-width: pcD(600);
		margin: 0 pcD($child_margin_half);
		padding: pcD($internal_margin);

		// border-radius: pcD(8);
		// box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 
		// -webkit-box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 
		// -moz-box-shadow: 0 pcD(15) pcD(20) rgba(0, 0, 0, 0.10); 

		&:nth-child(1), 
		&:nth-child(2)
		{
			margin-bottom: pcD(2 * $child_margin_half);
		}
		&:nth-child(3), 
		&:nth-child(4)
		{
			margin-bottom: pcD(35);
		}

		.img-container-wrapper
		{
			height: pcD(100 * $tablet_2nd_factor);
			margin: 0 pcD($internal_margin) 0 0;

			.image {
				max-width: pcD(100 * $tablet_2nd_factor);
				height: pcD(100 * $tablet_2nd_factor);
			}
		}

		.text-container-wrapper
		{
			.text-container
			{
				.top-line 
				{
					margin: 0 0 pcD(9) 0;

					.name,
					.position {
						// @include FONT-REGULAR();
						font-size: pcD(13 * $tablet_2nd_factor);
						line-height: pcD(19 * $tablet_2nd_factor);
					}

					.name {
						margin-right: pcD(20);
					}
				}

				.quote {
					// @include FONT-REGULAR();
					font-size: pcD(20 * $tablet_2nd_factor);
					line-height: pcD(24 * $tablet_2nd_factor);
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		flex-direction:row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		border-radius: pcM(8);
		box-shadow: 0 pcM(10) pcM(20) rgba(0, 0, 0, 0.10); 
		-webkit-box-shadow: 0 pcM(10) pcM(20) rgba(0, 0, 0, 0.10); 
		-moz-box-shadow: 0 pcM(10) pcM(20) rgba(0, 0, 0, 0.10);		

		&:nth-child(1), 
		&:nth-child(2),
		&:nth-child(3), 
		&:nth-child(4)
		{
			margin-bottom: pcM(15) !important;
			margin-top: pcM(15) !important;
			width: 83vw;
			max-width: pcM(400);
		}

		.img-container-wrapper
		{
			height: pcM(100);
			margin: pcM(10);
			text-align: center;

			.image {
				max-width: pcM(100);
				height: pcM(100);
			}
		}

		.text-container-wrapper {
			display: none;
		}

		.top-line-mobile
		{
			display: flex;
			flex-direction: column;
			width: 52%;
			margin: 0 0 0 pcM(14);
	
			.name-mobile,
			.position-mobile {
				@include FONT-REGULAR();
				font-size: pcM(13);
				line-height: pcM(19);
			}
	
			.name-mobile,
			.position-mobile {
				color: #000;
				margin-right: pcM(16);
				margin-bottom: pcM(5);
			}
	
			.position-mobile {
				color: #EC0016;
			}
		}
	
		.quote-mobile {
			display: block;
			@include FONT-REGULAR();
			font-size: pcM(20);
			line-height: pcM(24);
			color: #000;
			width: 100%;
			padding: pcM(10) pcM(20);
	
			&::before {
				content: "„"
			}
			&::after {
				content: "“"
			}
		}
	}
}