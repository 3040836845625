
@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

@import "./HighlightsVars.scss";

.HighlightsSlider 
{
	color: #fff;

	// override global class .module
	.module {
		height: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	.root-container {
		width: 100%; 
		margin: 55px 0;
	}

	.slick-slider 
	{
		width: 100%;
		overflow: hidden;
		position: relative;

		.slick-next, 
		.slick-prev {
			z-index: 2;
			font-size: 0;
			line-height: 0;
			position: absolute;
			top: ($img_max_height / 2)+px;
			display: block;
			width: 20px;
			height: 18px;
			padding: 0;
			transform: translateY(-50%);
			cursor: pointer;
			border: none;

			&:hover {
				.svg-icon .cls-1 { stroke: #ec0018; }
			}
			&.slick-disabled:hover {
				.svg-icon .cls-1 { stroke: #000; }
			}
		}
		.slick-disabled { 
			opacity: 0.10; 
			cursor: default;
		}
		// background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
		.slick-prev { 
			left: 60px; 
		}
		.slick-next { 
			right: 60px; 
		}

		.slick-list { z-index: 1; }
		.slick-track 
		{
			display: flex;
			flex-direction: row;

			.slick-slide 
			{
				display: flex;
				flex-direction: row;
				justify-content: center;

				.slide
				{
					// max-width: (444 + (2 * $child_margin_half))+px;		// 2*30 margin vom child left + right
					max-width: 1512px;
					display: flex;
					flex-direction: row;
					justify-content: center;
					flex-wrap: nowrap;
				}
			}
		}
	}

	//------------------------------------------------------------------------ animation
	.root-container[data-scroll]
	{
		//---------------------------
		$item_anim_delay: 0.15;
		//---------------------------
		.HighlightItem
		{
			transition: opacity 1s, transform 1s;
			transform: translateY(+50px);
			opacity: 0;

			@for $i from 1 to 4
			{
				&:nth-child(#{$i}) {
					transition-delay: (($i - 1) * $item_anim_delay)+s;	// $i := 0..(n-1) >> delay mit 0 Sekunden anfangen
					// transition-delay: ($i * $item_anim_delay)+s;		// $1 ::= 1..n >> delay mit <item_anim_delay> Sekunden anfangen
				}
			}
		}
	}
	.root-container[data-scroll=in]
	{
		.HighlightItem
		{
			opacity: 1;
			transform: translateY(0px);
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		.root-container {
			margin: pcD(55) 0;
		}
	
		.slick-slider 
		{
			.slick-next, 
			.slick-prev {
				top: pcD($img_max_height / 2);
				width: pcD(20);
				height: pcD(18);
			}
			.slick-prev { 
				left: pcD(60); 
			}
			.slick-next { 
				right: pcD(60); 
			}
	
			.slick-track 
			{
				.slick-slide 
				{
					.slide
					{
						max-width: pcD(1512);
					}
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		margin-bottom: pcM(20);
		
		.slick-slider
		{	
			.slick-next, 
			.slick-prev {
				top: pcM(94);
				width: pcM(20);
				height: pcM(18);
			}
		}
	}
}
