
@import "./colors.scss";
@import "./vars.scss";


@mixin bg-type($type, $grandientType, $isMobile) 
{
	$bgColor: $color_bg_lightGrey;
	@if $type == white
	{
		$bgColor: #fff; 
	}
	@else if $type == transparent
	{
		$bgColor: transparent;
	}
	@else
	{
		$bgColor: $color_bg_lightGrey;
	}

	@if $grandientType == gradTopDown
	{
		@if $isMobile
		{
			background: $bgColor left top / auto 146px repeat-x url("../assets/gradientgrey-top.png");
		}
		@else
		{
			// background: $bgColor left top / auto auto repeat-x url("../assets/grad_top_down.png");
			background: $bgColor left top / auto 356px repeat-x url("../assets/gradientgrey-top.png");
		}
	}
	@else if $grandientType == gradBottomUp
	{
		@if $isMobile
		{
			background: $bgColor left bottom / auto 146px repeat-x url("../assets/gradientgrey-bottom.png");
		}
		@else
		{
			// background: $bgColor left bottom / auto auto repeat-x url("../assets/grad_bottom_up.png");
			background: $bgColor left bottom / auto 356px repeat-x url("../assets/gradientgrey-bottom.png");
		}
	}
	@else
	{
		background: $bgColor;
	}
}


@mixin bg-stars($yPos) 
{ 
	@if $yPos == bottom
	{
		// background: left bottom / 100% auto no-repeat url("../assets/stars-bottom.png" ); 
		background: left bottom / auto auto repeat-x url("../assets/stars-bottom.png" ); 
	}
	@else
	{
		// background: left top / 100% auto no-repeat url("../assets/stars-top.png" ); 
		background: left top / auto auto repeat-x url("../assets/stars-top.png" ); 
	}
}


@mixin bg-stars-mobile($yPos) 
{ 
	@if $yPos == bottom
	{
		background: left bottom / 100% auto no-repeat url("../assets/stars-bottom-mobile.png" ); 
		// background: left bottom / auto auto repeat-x url("../assets/stars-bottom-mobile.png" ); 
	}
	@else
	{
		background: left top / 100% auto no-repeat url("../assets/stars-top-mobile.png" ); 
		// background: left top / auto auto repeat-x url("../assets/stars-top-mobile.png" ); 
	}
}