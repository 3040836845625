@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

.VideoTop
{
	position: relative;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	text-align: center;
	color: #fff;
	padding: 0 20px;

	.video-container 
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: transparent;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;

		.cover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		// box-shadow: 0px 0px 38px 50px rgba(#120f22,0.6);	// shift right, shift down, spread, blur,opacity

		// video-container verdunkeln (Layer mit ~50% alpha über Video)
		/*
		&:after {
			content: '';
			z-index: 1;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
		}
		*/

		video,
		.video-fallback 
		{
			// moderne Browser
			min-width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;

			// IE 11
			@media all and (-ms-high-contrast:none)
			{
				height: auto;
			}

			// Microsoft Edge Browser 12-18 (All versions before Chromium)
			// https://newbedev.com/how-to-detect-ie-and-edge-browsers-in-css
			// https://stackoverflow.com/questions/32201586/how-to-identify-microsoft-edge-browser-via-css
			@supports (-ms-ime-align:auto) 
			{
				// position: absolute;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				// left: 50%;
				// top: 50%;
				// transform: translate(-50%, -50%);
				object-fit: none;
			}
		}
	}

	.content 
	{
		z-index: 2;

		.title-main-container
		{
			width: 100%;
			background-image: url(../../assets/videoTop_deco_bar_d.png);
			background-size: 100vw auto;
			background-position: 0 62%;
			background-repeat: no-repeat;
			position: absolute;
			top: 30.4%;
			left: 0;

			&.hidden { display: none; }

			H1 {
				margin: 0;
				padding: 0;
				@include FONT-DBHead-BlackItalic();
				// font-family: $font-fallback;
				// font-style: italic;
				font-size: 6.3vw;
				line-height: 1.0em;
				// letter-spacing: -0.7vw;
	
				&::after {
					display: none;
				}
			}

			H2 {
				margin: 1.8vw 0 0 0;
				padding: 0;
				@include FONT-DBSans-Italic();
				// font-family: $font-sans;
				// font-style: italic;
				font-size: 2.91vw;
				line-height: 1.1em;

				&::after {
					display: none;
				}
			}
		}

		.arrow-down {
			width: 18px;
			height: 20px;
			// margin: 0 0 98px 0;
			margin: 0 0 15vh 0;
		}
	}

	//############################################################################## responsive
	/*
	@include breakpoint($tablet) 
	{
	}
	*/

	@include breakpoint($mobile) 
	{
		.content 
		{
			.title-main-container
			{
				background-image: url(../../assets/stars_bg_m.png);
				// background-size: 150.4vw 20vw;
				background-size: pcM(375) pcM(39);
				background-position: 50% 0;

				H1 {
					margin: pcM(3) 0 0 0;
					font-size: pcM(28);
				}

				H2 {
					font-size: pcM(16);
					line-height: pcM(24);
				}
			}

			.arrow-down {
				margin: 0 0 20vh 0;
			}
		}
	}
}
