
@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";


.Title 
{
	//####################################
	$bg_height: 145;
	//####################################

	// override global class .module
	&.embedded
	{
		display: flex;
		flex-direction: column;
	}

	&.embedded,
	.module 
	{
		align-items: center;
		overflow: hidden
	}

	&.show-deco-bar 
	{
		.deco-bar 
		{
			display: block; 
		}

		.title-container
		{
			H1, H2 { 
				margin-top: 53px;
			}
			H2:after { margin-top: 22px; }

			.subtitle-container 
			{
				margin-top: 30px;
			}
		}
	}

	.deco-bar 
	{
		z-index: 1;
		position: absolute;
		width: 100%;
		margin: 25px 0 0 0;
		overflow: hidden;
		height: $bg_height+px;
		display: none;

		.bar-container
		{
			z-index: 1;
			position: absolute;
			width: 100%;
			height: $bg_height+px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;

			.bar-left,
			.bar-right {
				width: 25%;
				height: 100%;
			}
			.bar-middle {
				width: 50%;
				height: 100%;
				background: transparent;
			}

			.bar-left { background: #fa6900; }
			.bar-right { background: #ec0016; }
		}

		.desktop {
			display: block;
			z-index: 2;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		.mobile {
			display: none;
			z-index: 2;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	
	&.embedded 
	{
		.title-container
		{
			.subtitle-container 
			{
				P
				{
					&.subtitle { max-width: 75%; }
				}
			}
		}	
	}
	.title-container
	{
		z-index: 2;

		H1, H2 
		{ 
			margin-top: 66px;
			margin-bottom: 0;
			padding: 0 0 $title-margin-bottom+px 0;

			&.with-subtitle { padding: 0 0 0 0; }
		}
		H2:after { margin-top: 26px; }

		.subtitle-container 
		{
			margin: 0 auto;
			margin: 23px 0 0 0;
			padding: 0 0 $title-margin-bottom+px 0;

			P
			{
				@include default_p();

				&.subtitle {
					// margin: 22px 0 0 0;
					// margin: 22px auto 0;
					// margin: 30px auto 0;
					margin: 0 auto;
					// 
					padding: 0;
					max-width: 70%;
					text-align: center;
					// padding: 0 0 $title-margin-bottom+px 0;
				}
			}
		}
	}
	

	//------------------------------------------------------------------------ animation
	h1[data-scroll], 
	h2[data-scroll] {
		transition: transform 1s;
		transform: scale(0);
	}
	h1[data-scroll=in], 
	h2[data-scroll=in]
	{
		transform: scale(1);
	}

	.subtitle[data-scroll] {
		transition: opacity 1s, transform 1s;
		transform: translateY(+50px);
		transition-delay: 0.25s;
		opacity: 0;
	}
	.subtitle[data-scroll=in]
	{
		opacity: 1;
		transform: translateY(0px);
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		&.show-deco-bar 
		{
			.title-container
			{
				H1, H2 { 
					margin-top: pcD(53);
				}
				H2:after { margin-top: pcD(22); }

				.subtitle-container 
				{
					margin-top: pcD(30);
				}
			}
		}

		.deco-bar 
		{
			margin: pcD(25) 0 0 0;
			height: pcD($bg_height);

			.bar-container
			{
				height: pcD($bg_height);
			}

			.desktop {
				display: block;
				width: auto;
				height: pcD($bg_height);
			}
		}

		.title-container
		{
			H1, H2 
			{ 
				margin-top: pcD(66);
				padding: 0 0 pcD($title-margin-bottom) 0;
			}
			H2:after { margin-top: pcD(26); }

			.subtitle-container 
			{
				margin: pcD(23) 0 0 0;
				padding: 0 0 pcD($title-margin-bottom) 0;

				p
				{
					&.subtitle {
						// max-width: 75%;
						font-size: pcD(16);
						line-height: pcD(24);
					}
				}
			}
		}
	}

	@include breakpoint($tablet_2nd) 
	{
		.title-container
		{
			.subtitle-container 
			{
				p
				{
					&.subtitle {
						// max-width: 75%;
						font-size: pcD(16 * $tablet_2nd_factor);
						line-height: pcD(24 * $tablet_2nd_factor);
					}
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		h1, h2 
		{
			padding: 0 0 pcM($title-mobile-margin-bottom) 0;
			margin-left: pcM(20);
			margin-right: pcM(20);
			font-size: pcM(28);
		}

		.title-container
		{
			& H2:after {
				margin-top: pcM(20);
				height: pcM(4);
				width: pcM(40);

			}

			.subtitle-container 
			{
				p
				{
					&.subtitle {
						padding: 0 0 pcM($title-mobile-margin-bottom) 0;
						margin-top: pcM(20);
						font-size: pcM(16);
						line-height: pcM(24);
					}
				}
			}
		}

		
		&.show-deco-bar 
		{
			& .title-container
			{
				& H2:after { display: none; }

				& .subtitle-container 
				{
					margin-top: pcM(18) !important;

					p{
						& .subtitle 
						{
							margin-top: 0;
							font-size: pcM(16);
							line-height: pcM(24);
						}
					}
				}
			}
		}

		.deco-bar 
		{
			height: pcM(75);

			.bar-container
			{
				display: none;
			}

			.desktop {
				display: none;
			}

			.mobile {
				display: block;
				width: pcM(564);
				height: pcM(75);
			}
		}
	}
}
