@import "../../scss/fonts.scss";
// @import "../../scss/colors.scss";
@import "../../scss/vars.scss";
// @import "../../scss/background.scss";

.ContactItem 
{
	//-----------------------------------------
	$img_WH: 380;
	$internal_margin: 24;
	$hover_borderW: 15;
	//-----------------------------------------

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	align-items: center;
	padding: 48px 60px;
	max-width: 649px;

	/*
	.img-container-wrapper
	{
		width: $img_WH+px;			   // ... bringt den IE11 durcheinander
		// height: $img_WH+px;				// ... IE11 FIX
		margin: 0;
		border-radius: 50%;				// ... wenn man border-radius auf dem Wrapper setzt, dann hat man immer einen Kreis (weil Wrapper quadratisch)
		overflow: hidden;

		box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 
		-webkit-box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 
		-moz-box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 

		.img-container-wrapper-inner 
		{
			height: $img_WH+px;				// ... IE11 FIX

			.image {
				max-width: $img_WH+px;
				width: auto;
				height: $img_WH+px;
				// border-radius: 50%;	   ... wenn man border-radius auf das Bild setzt, dann erhält man u.U. einen Oval (weil Bild nicht quadratisch sein muss)
			}
		}
	}
	*/

	.contact-hover-image
	{
		position: relative;
		border-radius: 50%;
		cursor: pointer;

		box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 
		-webkit-box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 
		-moz-box-shadow: 0 25px 99px rgba(0, 0, 0, 0.20); 

		.hover-image {
			z-index: 2;
			position: relative;
			border-radius: 50%;
			// max-width: $img_WH+px;
			width: $img_WH+px;
			height: auto;
		}
		IMG {
			vertical-align: middle;
		}

		&:hover:before {
			top: 0;
			left: 11%;
			opacity: 1.00;
		}
		&:before {
			z-index: 2;
			// background-image: url("https://omr.com/events-assets/img/Gruppe1428.bf591ef3.svg");
			background-image: url("../../assets/selection_bg.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			position: absolute;
			content: "";
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			opacity: 0;
			transition: all .3s ease-in-out;
		}

		&:hover:after {
			opacity: 1.00;
		}
		&:after {
			z-index: 3;
			box-sizing: border-box;		// WICHTIG! sonst wird border-width auf width dazuaddiert
			top: -1px;
			left: -1px;
			position: absolute;
			content: "";
			display: block;
			width: ($img_WH + 2)+px;
			height: ($img_WH + 2)+px;
			border-radius: 50%;
			background: transparent;
			opacity: 0;
			transition: all .3s ease-in-out;
			border: $hover_borderW+px solid #FA6922;
		}
	}

	.content-container-wrapper
	{
		width: 100%;

		.content-container
		{
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-content: center;
			justify-content: flex-start;
			align-items: center;

			.title {
				margin: 52px 0 0 0;
				@include FONT-H3;
				font-family: $font-sans-bold;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
			}

			.subline {
				margin: 11px 0 0 0;
			}
			
			.subline,
			.phone,
			.email,
			.teams
			{
				@include FONT-REGULAR;
				color: #000;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				
				A {
					text-decoration: none;
					color: #000;

					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-content: center;
					justify-content: center;

					.icon
					{
						//--------------------------------
						$icon_width: 24;
						//--------------------------------
						height: $icon_width+px;
						margin: 0 10px 0 0;

						.icon-img {
							width: $icon_width+px;
							height: auto;
						}
						.icon-default { display: block; }
						.icon-hover { display: none; }
					}

					&:hover 
					{ 
						color: $color-red; 
						.icon
						{
							.icon-default { display: none; }
							.icon-hover { display: block; }
						}
					}
				}
			}

			.full-name {
				margin: 26px 0 0 0;
				@include FONT-BOLD;
				color: #000;
				font-size: 24px;
				line-height: 34px;
				text-align: center;
			}
		}
	}

	//############################################################################## responsive
	@include breakpoint($tablet) 
	{
		padding: pcD(48) pcD(60);
		max-width: pcD(649);

		.contact-hover-image
		{
			box-shadow: 0 pcD(25) pcD(99) rgba(0, 0, 0, 0.20); 
			-webkit-box-shadow: 0 pcD(25) pcD(99) rgba(0, 0, 0, 0.20); 
			-moz-box-shadow: 0 pcD(25) pcD(99) rgba(0, 0, 0, 0.20); 

			.hover-image {
				width: pcD($img_WH);
			}

			&:after {
				top: pcD(-1);
				left: pcD(-1);
				width: pcD($img_WH + 2);
				height: pcD($img_WH + 2);
				border-width: pcD($hover_borderW);
			}
		}

		.content-container-wrapper
		{
			.content-container
			{
				.title {
					margin: pcD(52) 0 0 0;
					font-size: pcD(34);
					line-height: pcD(40);
				}

				.subline {
					margin: pcD(11) 0 0 0;
				}
				
				.subline,
				.phone,
				.email,
				.teams
				{
					font-size: pcD(24);
					line-height: pcD(34);

					A {
						.icon
						{
							height: pcD(32);
							margin: 0 pcD(10) 0 0;

							.icon-img { width: pcD(32); }
						}	
					}
				}

				.full-name {
					margin: pcD(26) 0 0 0;
					font-size: pcD(24);
					line-height: pcD(34);
				}
			}
		}
	}

	@include breakpoint($mobile) 
	{
		max-width: 100%;
		padding: pcM(40) pcM(20);

		.contact-hover-image
		{
			box-shadow: 0 pcM(25) pcM(99) rgba(0, 0, 0, 0.20); 
			-webkit-box-shadow: 0 pcM(25) pcM(99) rgba(0, 0, 0, 0.20); 
			-moz-box-shadow: 0 pcM(25) pcM(99) rgba(0, 0, 0, 0.20); 
				
			.hover-image {
				width: pcM(200);
				height: auto;
			}

			// &:before
			// {
			// 	display: none;
			// }
			
			&:after
			{
				top: pcM(-1);
				left: pcM(-1);
				width: pcM(202);
				height: pcM(202);
				border: pcM(8) solid #FA6922;
			}
		}
		
		.content-container-wrapper
		{	
			.content-container
			{	
				.title
				{
					font-size: pcM(20);
					line-height: pcM(28);
				}

				.subline
				{
					margin-top: pcM(28);
				}
			
				.subline,
				.phone,
				.email,
				.teams
				{					
					font-size: pcM(16);
					line-height: pcM(26);
					
					A {
	
						.icon
						{
							height: pcM(24);
							margin: 0 pcM(10) 0 0;
	
							.icon-img {
								width: pcM(24);
								height: auto;
							}
						}
					}
				}
	
				.full-name {
					margin: pcM(26) 0 pcM(5) 0;
					font-size: pcM(20);
					line-height: pcM(28);
				}
			}
		}

	}
}
